$color-main: '#FF214F';
$whiteColor: '#fff';
$blackColor: '#272932';


*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'GothamRounded-Light';
    src: url('./assets/fonts/GothamRounded-Light.otf') format('opentype'),
        url('./assets/fonts/GothamRoundedLight_21020.ttf') format('truetype');
}
@font-face {
    font-family: 'GothamRounded';
    src: url('./assets/fonts/GothamRounded-Book.otf') format('opentype'),
    url('./assets/fonts/GothamRoundedBook_21018.ttf') format('truetype');;
}
@font-face {
    font-family: 'GothamRounded-Bold';
    src: url('./assets/fonts/GothamRounded-Bold.otf') format('opentype'),
    url('./assets/fonts/GothamRoundedBold_21016.ttf') format('truetype');;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
    font-family: 'GothamRounded', sans-serif;
    scroll-behavior: smooth;
    @media(max-width:768px) {
        font-size: 60%;
    }
    @media(max-width:600px) {
        font-size: 58%;
    }
    @media(max-width:475px) {
        font-size: 48%;
    }

    
}

body {
    font-family: inherit;
}

button:focus {
    outline: none;
}
a, a:visited, a:hover{
    text-decoration: none;
}

