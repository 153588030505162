.itemContainer {
    width: 230px;
    min-height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 768px) {
        flex-direction: row;
        width: 311px;
        height: auto;
    }

    .imgContainer {
        width: 55px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
        }
    }

    .textContainer {
        margin-top: 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        color: #FFFFFF;
        font-family: 'GothamRounded-Bold', sans-serif;
        font-size: 1.8rem;
        line-height: 20px;
        text-align: center;
        span {
            margin-top:10px;
            font-size: 1.5rem;
            font-family: 'GothamRounded-Light', sans-serif;
            @media(max-width: 768px) {
                font-size: 1.6rem;
                line-height: 20px;
            }
        }
        @media(max-width: 768px){
            justify-content: flex-start;
            align-items: flex-start;
            text-align: start;
            padding-left: 25px;
            margin-top: 0;
            font-size: 2rem;
            line-height: 20px;
        }
    }
}