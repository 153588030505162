.productos {
    margin: 60px 0;
    padding: 10px;
    width: 100%;
    .textContainer {
        max-width: 660px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        h2 {
            
            color: #272932;
            font-family: 'GothamRounded-Bold', sans-serif;
            font-size: 3rem;
            line-height: 41.42px;
            width: 645px;
            max-width: 100%;
            text-align: center;
              
        }

        h3 {
            margin-top: 30px;
            color: #272932;
            font-size: 2.2rem;
            font-weight: 300;
            line-height: 30px;
            width: 100%;
            text-align: center;
        }
    }
}