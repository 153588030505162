.backgroundMobile {
    position: relative;
    clip-path: polygon(50% 2%, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
    margin-top: -100px;
    
        .lineDesktop {
            width: 90%;
            position: absolute;
            top: 68%;
            left: 51.5%;
            transform: translate(-50%, -50%);
            z-index: 5;
            svg {
                width: 100%;
            }
            @media(max-width: 1080px) {
                display: none;
            }
        }

        .lineMobile {
            width: 100%;
            position: absolute;
            top: 61%;
            transform: translateY(-50%);
            left: 0;
            z-index: 3;
            svg {
                width: 100%;
            }
            @media(min-width: 1081px) {
                display: none;
            }
        }
    
    
    // &::before {
    //     content:'';
    //     position: absolute;
    //     top: 67%;
    //     left: 51.5%;
    //     transform: translate(-50%, -50%);
    //     width: 1057px;
    //     height: 515px;
    //     background-image: url(../../assets/images/line-home.svg);
    //     background-size: contain;
    //     background-position: center;
    //     z-index: 30;
    // }
    @media(max-width: 1080px){
        background-color: #F7F3F3;
        padding-bottom: 20px;
    }

.section {
    padding-top: 80px;
    margin-top: -30px;
    width: 100%;
    min-height: 600px;
    background-color: #F7F3F3;
    z-index: -1;

    

    .container {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        h2 {
            color: #272932;
            font-family: 'GothamRounded', sans-serif;
            font-size: 3rem;
            font-weight: 300;
            line-height: 41.42px;
            width: 486px;
            max-width: 100%;
            text-align: center;

            span {
                font-family: 'GothamRounded-Bold', sans-serif;
            }
        }
    }
    
}
.sectionItems {
    position: relative;
    z-index: 3;
    margin-top: -300px;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 295.72px;
        width: 100%;
        background-image: url(../../assets/images/Onda-bg.svg);
        background-size: cover;
        background-position:top center;
    }
    .container {
        padding-top: 80px;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        position: relative;
        @media(max-width: 1080px) {
            flex-direction: column;
        }
        .line {
            position: absolute;
        }
    }
    
}
.lastItem {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    h4 {
        font-size: 1.8rem;
        margin-bottom: 30px;
        position: relative;

        &:not(:last-child)::before {
            content: 'v';
            color: red;
            position: absolute;
            font-weight: bold;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 10px;
        }
    }
}

.btn {
    margin: 70px auto;
    background-color: #FF214F;
    border-radius: 11px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.27);
    width: 413px;
    max-width: 95%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'GothamRounded-Bold', sans-serif;
    font-size: 1.6rem;
    line-height: 35px;
    text-align: center;
    border: none;
    cursor: pointer;
}

}