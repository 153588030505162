.content {
    padding: 28px;
    height: 434px;
    width: 366px;
    max-width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .info {
        width: 290px;
        height: 141px;
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        @media(max-width: 600px) {
            padding: 10px;
        }
        h4 {
            color: #000000;
            font-family: 'GothamRounded-Bold', sans-serif;
            font-size: 1.8rem;
            line-height: 20px;
            width: 250px;
            text-align: center;
            @media(max-width: 600px) {
                font-size: 2rem;
            }
        }

        p {
            color: #000000;
            font-family: 'GothamRounded', sans-serif;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 20px;
            width: 100%;
            text-align: center;
            @media(max-width: 600px) {
                font-size: 1.8rem;
            }
        }
    }
    .image {
        position: absolute;
        bottom: 40px;

        @media(max-width: 1080px) {
            position: unset;
        }
    }
}