@import '../../index.scss';

.header {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background-image: url(../../assets/images/bg-home.png);
    background-size: cover;
    background-position: center;
    @media(max-width: 1080px) {
        background-image:linear-gradient(200deg, transparent 0%, rgba(0,0,0,.95) 85%), url(../../assets/images/bg-home.png);
    }
    @media(max-height: 657px) {
        min-height: 125vh;
    }
    clip-path: polygon(100% 0, 100% 95%, 50% 99%, 0 95%, 0 0);

    .socialsContainer {
        width: 100%;
        height: 140px;
        clip-path: polygon(100% 0, 100% 73%, 50% 100%, 0 73%, 0 0);
        background-color: #fff;
        z-index: 1;
        position: sticky;
        top: 0;
        @media(max-width: 600px) {
            clip-path: polygon(100% 0, 100% 76%, 50% 100%, 0 76%, 0 0);
        }
        .socials {
            padding: 14px 60px 0px 60px;
            width: 1080px;
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            @media(max-width: 600px){
                padding: 0 10px 20px;
                height: 130px;
            }
            .logo {
                width: 46.27px;
                height: 76.3px;

                svg {
                    height: 100%;
                }
            }

            .contactItems {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 275px;
                height: 90px;
                max-width: 100%;
                @media (max-width: 768px) {
                    justify-content: flex-end;
                }
                .phones {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    
                    a {
                        color: #272932;
                        font-size: 1.35rem;
                        font-weight: 300;
                        line-height: 11.3px;
                        text-align: center;
                        &:first-child {
                            margin-bottom: 12px;
                        }
                        @media(max-width: 600px) {
                            font-size: 1.8rem;
                        }
                    }
                }

                .media {
                    position: relative;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;
                    @media (max-width: 768px) {
                        display: none;
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 2px;
                        top: 0;
                        left: -35px;
                        background-color: #C4C4C4;
                        border-radius: 10px;
                    }

                    a {
                        width: 18.54px;
                        height: 18.54px;
                        svg {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .aside {
        padding: 39px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        min-height: 90vh;
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #272932;
        clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
        @media (max-width: 1080px) {
            background: none;
            width: 100%;
            margin: 0 auto;
            align-items: center;
            padding: 10px;
            clip-path: unset;
            min-height: 100vh;
            justify-content: center;
        }
        @media (max-height: 657px) {
            min-height: 110vh;
        }
        @media(max-width: 600px) {
            justify-content: center;
        }

        .textContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
            @media (max-width: 1080px) {
                width: 100%;  
            }
            @media (max-width: 600px) {
                margin-top: 140px;
            }
            h1 {
                margin-top: 39px;
                color: #FFFFFF;
                font-family: 'GothamRounded-Bold', sans-serif;
                font-size: 3rem;
                letter-spacing: -1.03px;
                line-height: 41.42px;
                width: 460px;
                max-width: 100%;
                text-align: center;
            }

            h3 {
                margin-top: 20px;
                color: #FFFFFF;
                font-size: 2.48rem;
                font-weight: 300;
                line-height: 35px;
                width: 450px;
                max-width: 100%;
                text-align: center;
                  
            }

            button {
                background-color: #FF214F;
                border-radius: 11px;
                box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.27);
                width: 413px;
                max-width: 95%;
                height: 45px;
                color: #FFFFFF;
                font-family: 'GothamRounded-Bold', sans-serif;
                font-size: 1.6rem;
                font-weight: 700;
                line-height: 35px;
                text-align: center;
                border: none;
                outline: none;
                cursor: pointer;
                margin-top: 70px;

                @media (max-width: 600px) {
                    margin-top: 20px;
                }
            }
        }
    }
}