.formSection {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 100px;
    margin-top: 160px;
    .content {
        padding: 60px 87px;
        width: 587px;
        height: 761px;
        max-width:100%;
        background-color: #F7F3F3;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(max-width: 600px) {
            padding: 30px 10px;
            background-color: white;
        }
        h3 {
            color: #272932;
            font-family: 'GothamRounded-Light', sans-serif;
            font-size: 3rem;
            font-weight: 300;
            width: 100%;
            text-align: center;
        }
        h4{
            margin-top: 10px;
            color: #272932;
            font-family: 'GothamRounded-Light', sans-serif;
            font-size: 1.8rem;
            font-weight: 300;
            width: 100%;
            text-align: center;
        }

        .formGroup {
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;

            label {
                color: #000000;
                font-size: 1.4rem;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
            }
            input {
                padding: 10px;
                margin-top: 4px;
                background-color: #FFFFFF;
                border: 0.5px solid #A0A0A0;
                border-radius: 4px;
                width: 100%;
                height: 48px;
                font-family: inherit;
                &:focus {
                    outline: none;
                    border: 1px solid #FF214F;
                }
            }
            textarea {
                padding: 10px;
                margin-top: 4px;
                background-color: #FFFFFF;
                border: 0.5px solid #A0A0A0;
                border-radius: 4px;
                width: 100%;
                height: 91px;
                font-family: inherit;
                &:focus {
                    outline: none;
                    border: 1px solid #FF214F;
                }
            }
            
        }
        .btn {
            margin: 0 auto;
            margin-top: 50px;
            background-color: #FF214F;
            border-radius: 11px;
            box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.27);
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            font-family: 'GothamRounded-Bold', sans-serif;
            font-size: 1.6rem;
            line-height: 35px;
            text-align: center;
            transition: all .3s ease;
            &:disabled {
                background-color: #ccc;
                color: #666;
                box-shadow: unset;
                cursor: not-allowed;
            }
        }   
    }
    
}
.btn {
    margin: 0 auto;
    margin-top: 50px;
    background-color: #FF214F;
    border-radius: 11px;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.27);
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'GothamRounded-Bold', sans-serif;
    font-size: 1.6rem;
    line-height: 35px;
    text-align: center;
}   