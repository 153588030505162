.container {
    width: 100%;
    padding: 10px;
    min-height: 481px;
    background-color: #272932;
    clip-path: polygon(50% 5%, 100% 0, 100% 95%, 50% 100%, 0 95%, 0 0);

    .contenido {
        padding-top: 50px;
        margin: 0 auto;
        width: 834px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 90px;
        margin-bottom: 50px;
        @media(max-width: 768px) {
            padding: 70px 0 40px;
        }
        h2 {
            color: #FFFFFF;
            font-family: 'GothamRounded-Bold', sans-serif;
            font-size: 3rem;
            line-height: 41.42px;
            text-align: center;
        }

        .features {
            margin-top: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width: 768px){
                flex-direction: column;
                margin-top: 0;
                padding: 0 30px 30px;
            }
        }
    }
}